<template>
    <div>
        <ValidationObserver ref="validForm">
            <b-row class="mt-4">
                <b-col cols="12" md="6">
                    <ValidationProvider name="national_ids" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('national_id')">
                            <lined-textarea v-model="national_ids"
                                            :nowrap="false"
                                            :disabled="false"
                                            :styles="{ height: '15em', resize: 'both' }"
                                            :onlyNumberAndEnter="true"
                                            :validateError="errors[0]">
                            </lined-textarea>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col cols="12" md="2">
                    <b-button @click="excelExport" block variant="success">{{$t('excel')}}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
//Template
//Component
import {ValidationObserver, ValidationProvider} from "vee-validate";
import LinedTextarea from "@/components/elements/LinedTextarea";
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        LinedTextarea
    },
    metaInfo() {
        return {
            title: this.$t("yoksis_meb_graduate_questioning"),
        };
    },
    data() {
        return {
            national_ids: null
        }
    },
    methods: {
        async excelExport(){
            if(this.checkPermission('yoksisservice_bulkmebgraduateinformationexcelexport')){
                const isValid = await this.$refs.validForm.validate();
                let formData = {
                    national_ids: this.national_ids.split(/\n/)
                }
                if (isValid) {
                    YoksisService.bulkMebGraduateList(formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(e => {
                        this.showErrors(e, this.$refs.validForm)
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        }
    },
};
</script>

